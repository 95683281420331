<template>
  <v-app class="" :style="($root.lang == 'ar' ? merkazi : poppins) + ' ;height: 100vh;'">
    <v-app-bar
      flat
      fixed
      color="transparent"
      class="px-4"
      width="100vw"
      height="64px"
    >
      <router-link :to="'/'" v-slot="{ href, navigate }">
        <a style="z-index:100" :href="href" @click="navigate">
          <v-img
            src="media/logos/logo_text.png"
            contain
            width="100px"
            height="64px"
          />
        </a>
      </router-link>
      <v-spacer />

      <KTTopbar />
    </v-app-bar>

    <v-main style="height:100% ;">
      <div class="row" style="height:100%;">
        <div
          v-if="!$root.mobile"
          class="col-7"
          style="background-color:white;padding-top:64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                "
        >
          <lottie-animation
            :animationData="require('@/assets/animation/lf20_px2l8tdq.json')"
            style="width:450px !important; height: 450px !important;"
            :loop="true"
            :class="$root.lang == 'ar' ? 'animate_ar' : 'animate_en'"
          />
        </div>
        <div
          :class="$root.mobile ? 'col-12' : 'col-5'"
          :style="$root.mobile ? 'background-color:white;' : ''"
          style="padding-top:64px;display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;"
        >
          <router-view></router-view>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "auth",
  components: {
    LottieAnimation,
    KTTopbar: () => import("@/view/layout/header/Topbar"),
  },
  data() {
    return {
      poppins:"font-family: 'Poppins' !important;",
      merkazi:"font-family: 'Markazi Text' !important; "
    };
  },
  methods: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style>
.animate_ar {
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}
</style>
